import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/styles/tailwind.scss'
import 'element-plus/theme-chalk/index.css'
import 'img-mark/dist/style.css'

import App from './App.vue'
import router from './router'
import store from './store'

import '@/styles/index.scss'
import 'dayjs/locale/zh-cn'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { EnvName } from '@/config/NodeEnv'
import packageJson from '../package.json'
import { getUserInfo } from './utils/special/getUserInfo'
import { initTracking } from '@kzfe/learnable-lib/package/util/tracking'
import { redisStorage } from '@kzfe/learnable-lib'
import { datafluxRum } from '@cloudcare/browser-rum'
import { DOMAIN_MAP } from '@/config/config'
import http from '@/utils/http'
// 指令
import { setupDirective } from './directives'

const app = createApp(App)
app.config.globalProperties.$t = undefined
redisStorage.http = http
// if (process.env.NODE_ENV != 'development') {
// datafluxRum.init({
// 	applicationId: 'PCWEBTEACHER',
// 	datakitOrigin: process.env.NODE_ENV === EnvName.PRODUCTION ? '//datakit-online.kezhitech.com' : '//datakit-test.kezhitech.com', // 协议（包括：//），域名（或IP地址）[和端口号]
// 	env: import.meta.env.MODE,
// 	version: packageJson.version,
// 	sessionSampleRate: 100,
// 	sessionReplaySampleRate: 70,
// 	trackInteractions: true,
// 	traceType: 'ddtrace',
// 	allowedTracingOrigins: Object.values(DOMAIN_MAP),
// })
// }
datafluxRum.startSessionReplayRecording() // 会话重放
// if (process.env.NODE_ENV === EnvName.PRODUCTION) {
// 	Sentry.init({
// 		app,
// 		dsn: 'https://c13cbcd0eb4542a9b1f2721b54502b5f@sentry.kezhitech.com/36',
// 		release: packageJson.version,
// 		// debug: true,
// 		tracesSampleRate: 1.0,
// 		integrations: [new BrowserTracing()],
// 		ignoreErrors: [
// 			// Random plugins/extensions
// 			'top.GLOBALS',
// 			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
// 			'originalCreateNotification',
// 			'canvas.contentDocument',
// 			'MyApp_RemoveAllHighlights',
// 			'http://tt.epicplay.com',
// 			"Can't find variable: ZiteReader",
// 			'jigsaw is not defined',
// 			'ComboSearch is not defined',
// 			'http://loading.retry.widdit.com/',
// 			'atomicFindClose',
// 			// Facebook borked
// 			'fb_xd_fragment',
// 			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
// 			// reduce this. (thanks @acdha)
// 			// See http://stackoverflow.com/questions/4113268
// 			'bmi_SafeAddOnload',
// 			'EBCallBackMessageReceived',
// 			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
// 			'conduitPage',
// 		],
// 		denyUrls: [
// 			// Facebook flakiness
// 			/graph\.facebook\.com/i,
// 			// Facebook blocked
// 			/connect\.facebook\.net\/en_US\/all\.js/i,
// 			// Woopra flakiness
// 			/eatdifferent\.com\.woopra-ns\.com/i,
// 			/static\.woopra\.com\/js\/woopra\.js/i,
// 			// Chrome extensions
// 			/extensions\//i,
// 			/^chrome:\/\//i,
// 			// Other plugins
// 			/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
// 			/webappstoolbarba\.texthelp\.com\//i,
// 			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
// 		],
// 		beforeSend: (event, hint) => {
// 			const { response } = hint.originalException as any
// 			if (response && response.status === 404) {
// 				return null
// 			}
// 			return event
// 		},
// 	})
// }

app.use(ElementPlus, {
	locale: zhCn,
})

initTracking(
	{
		project_id: 100001,
	},
	{
		Appid: 100001,
		AppKey: '029bc98e-a59c-4550-88a7-86c358e4db94',
	},
	{
		mode: import.meta.env.MODE,
		getDistinctId: () => getUserInfo()?.userId || '',
		getIsLoginId: () => !!getUserInfo(),
	}
)

app.use(store).use(router).mount('#app')
setupDirective(app)
export default app
