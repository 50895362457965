<template>
	<div class="app-aside">
		<div class="logo" :class="{ only: !isShow && !showLogo }">
			<img v-show="showLogo" class="title" src="./img/logo-text.png" />
			<img v-show="!showLogo" class="img" src="./img/logo.png" />
		</div>
		<div class="menu">
			<AsideMenu />
		</div>
	</div>
</template>
<script setup lang="ts">
import { watch } from 'vue'
import { useStore } from 'vuex'
import { VuexStateKey } from '@/store/vuexEnum'
import AsideMenu from './components/AsideMenu.vue'
import { isBoolean } from 'lodash'

const store = useStore()
let isOpen = store.state[VuexStateKey.HAMBURGER_OPENED]
let showLogo = $ref(isBoolean(isOpen) ? isOpen : true)
const isShow = $computed(() => store.state[VuexStateKey.HAMBURGER_OPENED])

watch(
	() => isShow,
	val => {
		if (val) {
			showLogo = true
		} else {
			setTimeout(() => {
				showLogo = false
			}, 300)
		}
	}
)
</script>

<style scoped lang="scss">
.app-aside {
	height: 100vh;
	overflow: hidden;
	.logo {
		display: flex;
		height: 60px;
		align-items: center;
		padding-left: 18px;
		.img {
			width: 28px;
			height: 28px;
		}
		.title {
			width: 110px;
			min-width: 110px;
			height: 28px;
		}
	}
	.logo.only {
		padding-left: 16px;
	}
}
</style>
