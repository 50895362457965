import G from '@/config/config'
import { LocalStorageKey } from '@/config/enum'
import http from '@/utils/http'
import { getUserInfo } from '@/utils/special/getUserInfo'
import { UserInfo } from '@/vo/UserInfo'
import { RouteRecordRaw } from 'vue-router'
import { createStore } from 'vuex'
import { VuexActionKey, VuexMutationKey, VuexStateKey } from './vuexEnum'
type G = typeof G

export interface ENUM_S {
	[prop: string]: string | number
}

// 题库相关枚举
export interface QUESTION_LIB_ENUM {
	[prop: string]: QuestionLibEnumItem[]
}

// interface SUBMIT_STATUS

interface ResQuerySubjectList {
	subjectGroupList: Array<ResSubjectGroupList>
	subjectList: Array<ResSubjectList>
}

interface ResSubjectGroupList {
	type: number
	name: string
}
export interface ResSubjectList {
	index: number
	type: number
	name: string
	detailName: string
}

export interface TeacherPermissionInfo {
	permissionName: string
	permission: boolean
	openServiceType: number // 开通服务类型
}

// 题库相关枚举
export interface QuestionLibEnumItem {
	index: number | null
	desc: string
	parentIndexList: number[]
}
const store = createStore({
	state: {
		[VuexStateKey.G]: G,
		[VuexStateKey.userInfo]: getUserInfo(),
		[VuexStateKey.THIRD_USER_INFO]: {},
		ENUM_SUBJECT: {} as ENUM_S,
		ENUM_SUBJECT_GROUP: {} as ENUM_S,
		ENUM_SUBJECT_INDEX_TYPE: {} as ENUM_S,
		[VuexStateKey.workName]: localStorage.getItem(LocalStorageKey.WORK_NAME),
		[VuexStateKey.TEACHER_PERMISSION]: {} as TeacherPermissionInfo,
		[VuexStateKey.HAMBURGER_OPENED]: true,
		[VuexStateKey.EXTEND_BREAD]: '',
		[VuexStateKey.FOOTER_SHOW]: false,
		[VuexStateKey.QUESTION_LIB_ENUM]: {} as QUESTION_LIB_ENUM,
		[VuexStateKey.SUBSCRIBE_SHOW]: 1,
		[VuexStateKey.REVIEW_STATUS_DESC]: {
			1: '批改完成',
			2: '批改中',
			3: '线下批改',
			4: '教师在线批改',
			5: 'AI不可批',
		},
		[VuexStateKey.REVIEW_TYPE]: {
			1: 'AI批改',
			2: '教师线上批改',
			3: '教师线下批改',
		},
		[VuexStateKey.REVIEW_VALIDITY]: {
			0: '默认',
			1: '正确',
			2: '半对',
			3: '错误',
			4: '未答',
		},
	},
	mutations: {
		[VuexMutationKey.updateUserInfo](state, userInfo: UserInfo) {
			console.log('updateUserInfo', userInfo)
			state[VuexStateKey.userInfo] = userInfo === undefined ? undefined : Object.assign({}, userInfo)
		},
		[VuexMutationKey.updateThirdUserInfo](state, userInfo: UserInfo) {
			console.log('updateThirdUserInfo', userInfo)
			state[VuexStateKey.THIRD_USER_INFO] = userInfo === undefined ? undefined : Object.assign({}, userInfo)
		},
		[VuexMutationKey.setEnumSubject](state, res: ResQuerySubjectList) {
			res.subjectGroupList.forEach(item => {
				state.ENUM_SUBJECT_GROUP[item.type] = item.name
				state.ENUM_SUBJECT_GROUP[item.name] = item.type
			})
			res.subjectList.forEach(item => {
				state.ENUM_SUBJECT[item.index] = item.detailName
				state.ENUM_SUBJECT[item.detailName] = item.index
				state.ENUM_SUBJECT_INDEX_TYPE[item.index] = item.type
			})
		},
		[VuexMutationKey.setWorkName](state, workName: string) {
			state[VuexStateKey.workName] = workName
		},
		[VuexMutationKey.setTeacherPermission](state, permissionInfo: TeacherPermissionInfo) {
			state[VuexStateKey.TEACHER_PERMISSION] = permissionInfo
		},
		[VuexMutationKey.toggleHamburger](state, type: 'open' | 'close' | undefined) {
			if (type) {
				state[VuexStateKey.HAMBURGER_OPENED] = type === 'open'
			} else {
				state[VuexStateKey.HAMBURGER_OPENED] = !state[VuexStateKey.HAMBURGER_OPENED]
			}
		},
		[VuexMutationKey.setExtendBread](state, breadName: string) {
			state[VuexStateKey.EXTEND_BREAD] = breadName
		},
		[VuexMutationKey.changeFooterShow](state, isShow: boolean) {
			state[VuexStateKey.FOOTER_SHOW] = isShow
		},
		[VuexMutationKey.setQuestionLibEnum](state, questionLibEnum: QUESTION_LIB_ENUM) {
			state[VuexStateKey.QUESTION_LIB_ENUM] = questionLibEnum
		},
		[VuexMutationKey.changeSubscribeShow](state, type: 1 | 2 | 3) {
			state[VuexStateKey.SUBSCRIBE_SHOW] = type
		},
	},
	actions: {
		[VuexActionKey.setEnumSubjectAsync](context) {
			http<ResQuerySubjectList>(
				'get',
				'/api/task/querySubjectList',
				{},
				{
					domainName: 2,
				}
			).then((res: ResQuerySubjectList) => {
				context.commit('setEnumSubject', res)
			})
		},
		[VuexActionKey.setTeacherPermission](context) {
			return http<TeacherPermissionInfo[]>(
				'GET',
				'/api/teacher/checkPermission',
				{},
				{
					domainName: 'user',
				}
			).then(res => {
				context.commit(VuexMutationKey.setTeacherPermission, res)
			})
		},
		// 获取题库相关枚举
		[VuexActionKey.setQuestionLibEnum](context) {
			return http<QUESTION_LIB_ENUM>('GET', '/enums/queryEnumList', {}, { domainName: 'question' }).then(res => {
				if (res?.schoolBankTypeEnum) {
					// 处理来源
					const bankAllType = res.schoolBankTypeEnum.find(item => item.desc === '全部')!
					const otherBankTypes = res.schoolBankTypeEnum.filter(item => item.desc !== '全部')
					res.schoolBankTypeEnum = [bankAllType, ...otherBankTypes]
				}
				if (res?.difficultyEnum) {
					// 处理难度
					res.difficultyEnum = [{ index: null, desc: '全部', parentIndexList: [] }, ...res.difficultyEnum.filter(item => item.desc !== '全部')]
				}

				context.commit(VuexMutationKey.setQuestionLibEnum, res)
			})
		},
	},
	modules: {},
})

export default store
