import { UserInfo, UserType } from '@/vo/UserInfo'
import http from '@/utils/http'
import { keysOf } from 'element-plus/es/utils'

type LoginMode = 'account' | 'code'
export interface LoginData {
	mode: LoginMode
	form: {
		username: string
		password: string
		code: string
	}
	isSendSmsing: boolean
	passwordType: string
	loading: boolean
	time: number
}

export const OperatorLoginErrorMap = {
	[40100]: '账号不存在',
	[40101]: '账号被禁用',
	[40110]: '内部账号未分配学校',
}

export type OperatorLoginErrorCode = keyof typeof OperatorLoginErrorMap

// 手机号验证码登录
export function apiCodeLogin(params: { phoneNumber: string; verificationCode: string; userType: UserType }) {
	return http<UserInfo>(
		'POST',
		'/api/sms/verificationCode/login/noAuth',
		{ ...params, appType: 4 },
		{ domainName: 'user', showMsg: false, headers: { teacherId: '' } }
	)
}

// 教师账号密码登录
export function apiTeacherAccountLogin(params: { account: string; password: string; userType: UserType }) {
	return http<UserInfo>('POST', '/api/weChat/applet/teacher/accountLogin/noAuth', params, { domainName: 'user', showMsg: false, headers: { teacherId: '' } })
}

// 运营人员账号密码登录
export function apiOperatorAccountLogin(params: { account: string; password: string }) {
	return http<UserInfo>('POST', '/api/weChat/applet/staff/accountLogin/noAuth', params, { domainName: 'user', showMsg: false, headers: { teacherId: '' } })
}

// 查询运营负责的学校列表
export interface OperatorSchoolItem {
	id: number
	schoolName: string
}
export function apiQueryStaffSchools(): Promise<OperatorSchoolItem[]> {
	return http<OperatorSchoolItem[]>('GET', '/api/staff/school', {}, { domainName: 'user', headers: { teacherId: '' } })
}

// 查询学校下教师列表
export interface SchoolTeacherItem {
	userId: string
	teacherId: string
	name: string
	phone: string
	accountActivatedStatus: 0 | 1 // 0未激活 1已激活
	__selected?: boolean
}
export function apiQuerySchoolTeacherList(schoolId: number) {
	return http<SchoolTeacherItem[]>('GET', '/api/school/teacherList', { schoolId }, { domainName: 'user', headers: { teacherId: '' } })
}

// 运营人员切换老师
export function apiChangeTeacherAccount(teacherId: string) {
	return http<UserInfo>('GET', '/api/staff/changeTeacherAccount', { teacherId }, { domainName: 'user', headers: { teacherId: '' } })
}

// 教育钉老师登陆
export function apiOpenTeacherLogin(params: { appId: string; sign: string }) {
	return http<UserInfo>('POST', '/api/open/teacher/login', params, { domainName: 'user', headers: { appId: params.appId } })
}