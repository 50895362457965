import { setPageTitle } from '@/utils/common/system'
// import Main from '@/components/Main/MainHeader.vue'
import Main from '@/layout/Layout.vue'
import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import _ from 'lodash'
import { isLogin } from '@/utils/utils'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import store from '@/store'
import { ElMessage } from 'element-plus'
import { VuexStateKey } from '@/store/vuexEnum'
import { TeacherPermissionInfo } from '@/store'
// import { setKeepAliveAndTransition, setRalations, routeQueue } from 'vue-page-like-app'
// import { getRouteComponent } from '@kzfe/learnable-lib/package/vue3/framework/router/getRouteComponent'
import { apiHandleEventTracking } from '@kzfe/learnable-lib/package/vue3/framework/api/eventTracking'
import { getUSerAgen, getUid } from '@kzfe/learnable-lib/package/util/eventTracking'
import { TRACKING_CONFIG } from '@/config/eventTracking'
import { getUserInfo } from '@/utils/special/getUserInfo'
import G from '@/config/config'

import { SessionStorageTrackingKey, SessionStorageTracking } from '@kzfe/learnable-lib/package/util/tracking'
import { getNpsMeterId, npsMeter } from '@/utils/npsMeter'
import { queryZZDThirdAppUserInfo, queryJYDThirdAppUserInfo } from '@/utils/common/user'
import { checkThirdApp } from '@/utils/route'

export async function getRouteComponent(name: string, componentPromise: Promise<typeof import('*.vue')>) {
	const comp = await componentPromise
	Object.assign(comp.default, {
		name,
	})
	return comp
}

export const routes: Array<RouteRecordRaw> = [
	{
		path: '/test',
		name: 'test',
		component: () => import('../views/Test.vue'),
	},

	{
		path: '/',
		name: 'homework',
		redirect: '/homework',
		component: Main,
		meta: {
			title: '作业',
		},
		children: [
			{
				path: '/homework',
				name: 'homeworkList',
				component: () => import('../views/HomeworkList/HomeworkList.vue'),
				meta: {
					icon: '&#xe711;',
					title: '作业批改',
					menuShow: true,
					isTracking: true,
					moduleId: 100001004,
					parentName: 'homeworkList',
					openServiceType: 101,
					npsMeterKey: {
						production: '3f87fd9698373704',
						staging: '8c1492692e899729',
					},
					// npsMeterKey: '6ca8c35182daa28c',
				},
			},
			{
				path: '/homework-detail',
				name: 'homeworkDetail',
				component: () => import('../views/HomeworkList/HomeworkDetail.vue'),
				meta: {
					icon: '&#xe711;',
					title: '作业详情',
					parentName: 'homeworkList',
					isTracking: true,
					moduleId: 100001005,
				},
			},
			{
				path: '/upload-home',
				name: 'uploadHome',
				component: () => import('../views/UploadHome/UploadHome.vue'),
				meta: {
					icon: '&#xe711;',
					title: '作业上传',
					parentName: 'homeworkList',
					isTracking: true,
					moduleId: 100001009,
				},
			},
			{
				path: '/gradingTrace',
				name: 'gradingTrace',
				component: () => import('../views/HomeworkList/GradingTrace.vue'),
				meta: {
					icon: '&#xe711;',
					title: '有痕打印',
					parentName: 'homeworkList',
					isTracking: true,
					moduleId: 100001010,
				},
			},
			{
				path: '/student-answer-detail',
				name: 'studentAnswerDetail',
				component: () => import('../views/HomeworkList/StudentAnswerDetail.vue'),
				meta: {
					title: '学生详情',
					parentName: 'homeworkList',
					isTracking: true,
					moduleId: 100001006,
				},
			},
			{
				path: '/single-question-review-detail',
				name: 'singleQuestionReviewDetail',
				component: () => import('../views/HomeworkList/SingleQuestionReviewDetail.vue'),
				meta: {
					title: '题目详情',
					parentName: 'homeworkList',
					isTracking: true,
					moduleId: 100001007,
				},
			},
			{
				path: '/homework-picture-upload',
				name: 'homeworkPictureUpload',
				component: () => import('../views/HomeworkPicUpload/Upload.vue'),
				meta: {
					icon: '&#xe711;',
					title: '上传作业图片',
					hideAside: true,
					parentName: 'homeworkList',
				},
			},
			{
				path: '/homework-picture-upload-result',
				name: 'homeworkPictureUploadResult',
				component: () => import('../views/HomeworkPicUpload/UploadResult.vue'),
				meta: {
					icon: '&#xe711;',
					title: '上传记录详情',
					hideAside: true,
					parentName: 'homeworkList',
				},
			},
			{
				path: '/uploadRecord',
				name: 'uploadRecord',
				component: () => import('../views/UploadRecord/UploadRecord.vue'),
				meta: {
					title: '作业记录',
					icon: '&#xe711;',
					hideAside: true,
					parentName: 'homeworkList',
				},
			},
			{
				path: '/studentDetail',
				name: 'studentDetail',
				component: () => import('../views/StudentDetail/StudentDetail.vue'),
				meta: {
					title: '查看原图',
					icon: '&#xe711;',
					hideAside: true,
					parentName: 'homeworkList',
				},
			},
			{
				path: '/homeworkCorrect',
				name: 'homeworkCorrect',
				component: () => import('../views/HomeworkCorrect/HomeworkCorrect.vue'),
				meta: {
					icon: '&#xe711;',
					title: '在线批改作业',
					parentName: 'homeworkList',
					isTracking: true,
					moduleId: 100001008,
				},
			},
			{
				path: '/exerciseHomework',
				name: 'exerciseHomework',
				component: () => import('../views/ExerciseHomework/ExerciseHomeworkList.vue'),
				meta: {
					title: '布置练习册作业',
					hideAside: true,
					menuShow: true,
					parentName: 'exerciseHomework',
					isTracking: true,
					moduleId: 100001011,
					openServiceType: 102,
				},
			},
			{
				path: '/homeworkDesign',
				name: 'homeworkDesign',
				component: () => import('../views/DesignHomework/DesignHomework.vue'),
				meta: {
					title: '定制练习册',
					menuShow: true,
					parentName: 'homeworkDesign',
					openServiceType: 103,
				},
			},
			{
				path: '/deisgnHomework-select-question',
				name: 'designHomeworkSelectQuestion',
				component: () => import('../views/DesignHomework/DesignHomeworkSelectQuestion.vue'),
				meta: {
					icon: '&#xe711;',
					title: '设计作业选题',
					hideAside: true,
					parentName: 'homeworkDesign',
				},
			},
			{
				path: '/exerciseHomework-select-question',
				name: 'exerciseHomeworkSelectQuestion',
				component: () => import('../views/ExerciseHomework/ExerciseHomeworkSelectQuestion.vue'),
				meta: {
					icon: '&#xe711;',
					title: '练习册作业选题',
					hideAside: true,
					parentName: 'exerciseHomework',
					isTracking: true,
					moduleId: 100001012,
				},
			},
			{
				path: '/design-homework-preview',
				name: 'designHomeworkPreview',
				component: () => import('../views/DesignHomework/DesignHomeWorkPreview.vue'),
				meta: {
					icon: '&#xe711;',
					title: '设计作业预览',
					hideAside: true,
					parentName: 'homeworkDesign',
				},
			},
		],
	},
	{
		path: '/analysisReport',
		name: 'analysisReport',
		redirect: '/classReportList',
		component: Main,
		// component: getRouteComponent('analysisReport', import('@/layout/Layout.vue')),
		meta: {
			icon: '&#xe711;',
			title: '教研',
		},
		children: [
			{
				path: '/classReportList',
				name: 'classReportList',
				component: () => import('../views/ClassAnalysisList/ClassAnalysisList.vue'),
				meta: {
					icon: '&#xe711;',
					title: '学情报告',
					menuShow: true,
					isTracking: true,
					moduleId: 100001001,
					parentName: 'classReportList',
					openServiceType: 201,
					npsMeterKey: {
						production: '04b51b7332a561b3',
						staging: 'af1d8d5729e27ec4',
					},
				},
			},
			{
				path: '/weekReportDetail',
				name: 'weekReportDetail',
				component: () => import('../views/ClassAnalysisList/WeekAnalysisDetail.vue'),
				meta: {
					icon: '&#xe711;',
					title: '阶段学情',
					parentName: 'classReportList',
					menuShow: false,
					isTracking: true,
					moduleId: 100001002,
				},
			},
			{
				path: '/chapterReportDetail',
				name: 'chapterReportDetail',
				component: () => import('../views/ClassAnalysisList/ChapterAnalysisDetail.vue'),
				meta: {
					icon: '&#xe711;',
					title: '章节报告',
					parentName: 'classReportList',
					menuShow: false,
					isTracking: true,
					moduleId: 100001003,
				},
			},
			{
				path: '/classMistakeQuestionList',
				name: 'classMistakeQuestionList',
				// component: getRouteComponent('classMistakeQuestionList', import('../views/ClassMistakeQuestion/mistakeQuestionList.vue')),
				component: () => import('../views/ClassMistakeQuestion/mistakeQuestionList.vue'),
				meta: {
					icon: '&#xe711;',
					title: '班级错题',
					menuShow: true,
					parentName: 'classMistakeQuestionList',
					openServiceType: 202,
					npsMeterKey: {
						production: '81c781558f7165c6',
						staging: 'c15662e2a55c1df5',
					},
					isTracking: true,
					moduleId: 100001013,
				},
			},
			{
				path: '/designHomeWorkList',
				name: 'designHomeWorkList',
				component: () => import('../views/DesignHomeWorkList/DesignHomeWorkList.vue'),
				meta: {
					icon: '&#xe711;',
					title: '设计作业管理',
					menuShow: true,
					isTracking: true,
					moduleId: 100001035,
					parentName: 'designHomeWorkList',
					openServiceType: 203,
				},
			},
			{
				path: '/designworkEdit',
				name: 'designworkEdit1',
				component: () => import('../views/DesignWorkEdit/DesignworkEdit.vue'),
				meta: {
					icon: '&#xe711;',
					title: '作业设计',
					menuShow: false,
					customBread: true,
				},
			},
			{
				path: '/designPreview',
				name: 'designPreview',
				component: () => import('../views/DesignPreview/DesignPreview.vue'),
				meta: {
					icon: '&#xe711;',
					title: '题目预览与下载',
					// isTracking: true,
					// moduleId: 100001001,
				},
			},
		],
	},
	{
		path: '/questionLib',
		name: 'questionLib',
		redirect: '/aiQuestionLib',
		component: Main,
		meta: {
			title: '题库',
		},
		children: [
			{
				path: '/personalQuetionLib',
				name: 'personalQuetionLib',
				component: () => import('../views/PersonalQuetionLib/PersonalQuetionLib.vue'),
				meta: {
					icon: '&#xe711;',
					title: '题库管理',
					menuShow: true,
				},
			},
			{
				path: '/personalQuestionDetail',
				name: 'personalQuestionDetail',
				component: () => import('../views/PersonalQuetionLib/PersonalQuestionDetail.vue'),
				meta: {
					title: '详情',
					hideAside: true,
					parentName: 'personalQuetionLib',
				},
			},
			{
				path: '/createQuestionLib',
				name: 'createQuestionLib',
				component: () => import('../views/PersonalQuetionLib/CreateQuestionLib.vue'),
				meta: {
					title: '新建题库',
					hideAside: true,
					parentName: 'personalQuetionLib',
				},
			},
			{
				path: '/teacherQuestionList',
				name: 'teacherQuestionList',
				component: () => import('../views/PersonalQuetionLib/TeacherQuestionList.vue'),
				meta: {
					title: '我的上传题目',
					hideAside: true,
				},
			},
			{
				path: '/aiQuestionLib',
				name: 'aiQuestionLib',
				component: () => import('../views/aiQuestionLib/AiQuestionLib.vue'),
				meta: {
					icon: '&#xe711;',
					title: '批改题库',
					menuShow: true,
					isTracking: true,
					moduleId: 100001018,
					parentName: 'aiQuestionLib',
					openServiceType: 301,
					npsMeterKey: {
						production: '556ef449af29215e',
						staging: '01adc8ee4a1f9b1c',
					},
				},
			},
			{
				path: '/teachQuestionLib',
				name: 'teachQuestionLib',
				component: () => import('../views/teachQuestionLib/TeachQuestionLib.vue'),
				meta: {
					icon: '&#xe711;',
					title: '教研题库',
					menuShow: true,
					isTracking: true,
					moduleId: 100001027,
					parentName: 'teachQuestionLib',
					openServiceType: 302,
					npsMeterKey: {
						production: '8e64cb18b23362e3',
						staging: '95ffa52ea2a2aa5b',
					},
				},
			},
			{
				path: '/designworkEdit',
				name: 'designworkEdit',
				component: () => import('../views/DesignWorkEdit/DesignworkEdit.vue'),
				meta: {
					icon: '&#xe711;',
					title: '作业设计',
					menuShow: false,
					customBread: true,
				},
			},
		],
	},
	{
		path: '/demonstration',
		name: 'demonstration',
		component: () => import('../views/Demonstration/Demonstration.vue'),
		meta: {
			title: '作业讲评',
			parentName: 'homeworkList',
		},
	},
	{
		path: '/calc',
		name: 'calc',
		component: () => import('../views/Calc/Calc.vue'),
		meta: {
			title: '计算',
			parentName: 'calc',
			noLogin: true,
		},
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		redirect: '/dashboard',
		component: Main,
		meta: {
			menuShow: true,
			title: '数据看板',
		},
		children: [
			{
				path: '/dashboard/schoolBoard',
				name: 'schoolBoard',
				component: () => import('../views/Dashboard/Dashboard.vue'),
				meta: {
					icon: '&#xe711;',
					title: '服务使用情况',
					menuShow: true,
					parentName: 'schoolBoard',
					openServiceType: 401,
				},
			},
		],
	},

	{
		path: '/login',
		name: 'login',
		component: () => import('../views/Login/Login.vue'),
		meta: {
			isConstRoute: true,
			noLogin: true,
		},
	},
	{
		path: '/operator/login',
		name: 'operatorLogin',
		component: () => import('../views/Login/OperatorLogin.vue'),
		meta: {
			isConstRoute: true,
			noLogin: true,
		},
	},
	{
		name: '404',
		path: '/:pathMatch(.*)',
		component: () => import('../views/404/404.vue'),
		meta: {
			title: '404',
			noLogin: true,
		},
	},
]

export const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
})
router.afterEach((to, from) => {
	NProgress.done()
	if (to.meta.title) {
		setPageTitle(`小可教师版—` + to.meta.title)
	}

	const sessionStorageTracking: SessionStorageTracking = {
		page: {
			id: (to.name as string) || '',
			name: to.meta.title || '',
			event_tracking_module_id: to.meta.moduleId,
		},
		prePage: {
			id: (from.name as string) || '刷新页面',
			name: from.meta.title || '刷新页面',
			url: `${window.location.origin}${from.fullPath}`,
		},
	}

	sessionStorage.setItem(SessionStorageTrackingKey, JSON.stringify(sessionStorageTracking))

	if (to.meta.isTracking) {
		apiHandleEventTracking(
			{
				distinct_id: !to.meta.noLogin ? getUserInfo()?.userId || '' : '',
				project_id: G.projectId,
				page_id: (to.name as string) || '',
				page_name: to.meta.title || '',
				event_tracking_module_id: to.meta.moduleId || 1,
				event: 'ViewPage',
				properties: {
					is_login_id: !to.meta.noLogin,
					ua: getUSerAgen().userAgent,
					os: getUSerAgen().platform,
					log_id: getUid(),
					event_tracking_version: TRACKING_CONFIG.EventVersion,
					cli_type: G.cli_type,
					url: `${window.location.origin}${to.fullPath}`,
					referer: `${window.location.origin}${from.fullPath}`,
					pre_page_id: (from.name as string) || '刷新页面',
					pre_page_name: from.meta.title || '刷新页面',
				},
			},
			TRACKING_CONFIG,
			{
				mode: import.meta.env.MODE,
			}
		)
	}

	const elMain = document.querySelector('.el-main')
	// 重置滚动条
	if (elMain) {
		elMain.scrollTop = 0
	}
	npsmeter.close()
	// if (to.name != 'login') {
	// 	npsMeter(
	// 		getNpsMeterId({
	// 			production: 'be428af5b23015a0',
	// 			staging: 'bbf787f9b6f96fdc',
	// 		})
	// 	)
	// }
	if (to.meta.npsMeterKey) {
		npsMeter(getNpsMeterId(to.meta.npsMeterKey))
	}
})

export default router

NProgress.configure({ showSpinner: false })

router.beforeEach(async (to, from, next) => {
	NProgress.start()
	const noLogin = to?.meta?.noLogin
	const needLogin = noLogin === void 0 ? true : !noLogin
	// 判断来源
	const thirdResult = checkThirdApp(to, from)
	if (thirdResult.third) {
		if (isLogin()) {
			await store.dispatch('setTeacherPermission')
			checkTeacherPermission(to)
			next()
		} else {
			// 获取用户信息：参数
			const { appId, sign, nonce, teacherId }: any = to.query
			let userinfo
			if (thirdResult.zzd) {
				userinfo = await queryZZDThirdAppUserInfo({ appId, sign, nonce, teacherId })
			} else if (thirdResult.jyd) {
				userinfo = await queryJYDThirdAppUserInfo({ appId, sign, nonce })
			}
			if (userinfo) {
				try {
					await store.dispatch('setTeacherPermission')
					checkTeacherPermission(to)
					next()
				} catch (e) {
					console.log(e)
					next()
				}
			} else {
				next({
					name: 'login',
				})
			}
		}
	} else if ((!/404/.test(to.path) && to.name === '404') || needLogin) {
		if (isLogin()) {
			try {
				await store.dispatch('setTeacherPermission')
				checkTeacherPermission(to)
				next()
			} catch (e) {
				console.log(e)
				next()
			}
		} else {
			console.log('this route need login.')
			next({
				name: 'login',
			})
		}
	} else {
		next()
	}
	const subjects = Object.keys(store.state[VuexStateKey.ENUM_SUBJECT] || {})
	if (subjects.length == 0 && to.name !== 'login' && to.name !== 'operatorLogin') {
		store.dispatch('setEnumSubjectAsync')
		store.dispatch('setQuestionLibEnum')
	}
})

function checkTeacherPermission(to: RouteLocationNormalized) {
	const userInfo = getUserInfo()
	const teacherPermission = store?.state[VuexStateKey.TEACHER_PERMISSION] as unknown as TeacherPermissionInfo[]
	const permissionList = teacherPermission.filter(item => item.permission)
	if (!permissionList.length) {
		router.replace({
			name: userInfo?.__isOperator ? 'operatorLogin' : 'login',
		})
		ElMessage.info('未开通权限，请联系管理员!')
		return
	} else {
		const openTypeList = permissionList.map(item => item.openServiceType)
		// 判断前往的路由是否依旧可以访问
		const routeChildrens = _.flatten(routes.map(route => route.children))
		const findedRoute = routeChildrens.find(route => route?.name === to.meta.parentName)
		if (findedRoute && findedRoute.meta?.menuShow && openTypeList.indexOf(findedRoute.meta.openServiceType || -1) < 0) {
			ElMessage.info('权限变更，请重新登录!')
			router.replace({ name: userInfo?.__isOperator ? 'operatorLogin' : 'login' })
			// const newRoute = routeChildrens.find(route => route?.meta?.openServiceType === openTypeList[0])
			// newRoute ? router.replace({ name: newRoute.name }) : router.replace({ name: 'login' })
		}
	}
}
