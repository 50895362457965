<template>
	<div>
		<!-- <router-view v-slot="{ Component, route }">
			<keep-alive :include="routeQueueArr">
				<component :is="Component" :key="route.name" />
			</keep-alive>
		</router-view> -->
		<router-view />
		<ActivateTipDialog :show-dialog="showSubscribeDialog" :title="tipTitle" />
	</div>
</template>
<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { VuexStateKey } from '@/store/vuexEnum'
import { LocalStorageKey } from './config/enum'
import { checkSameUser } from '@kzfe/learnable-lib'
import { useRouter } from 'vue-router'
import ActivateTipDialog from '@/views/Login/components/ActivateTipDialog.vue'

let router = useRouter()
const store = useStore()
let showSubscribeDialog = $ref(false)
let tipTitle = $ref('')
const subscribeType = $computed(() => store.state[VuexStateKey.SUBSCRIBE_SHOW])

watch(
	() => subscribeType,
	() => {
		if (subscribeType !== 1) {
			showSubscribeDialog = true
			subscribeType === 3 ? (tipTitle = '关注公众号获取作业消息') : (tipTitle = '关注公众号获取可之服务最新动态')
		} else {
			showSubscribeDialog = false
		}
	}
)

onMounted(() => {
	checkSameUser(LocalStorageKey.USER_INFO, () => {
		let info = router.resolve({
			name: 'login',
		})
		let url = location.origin + info.fullPath
		location.href = url
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve('')
			}, 500)
		})
	})
})
</script>
<style lang="scss">
body {
	width: 100vw;
	min-width: 1200px;
	overflow-x: scroll;
	overflow-y: hidden;
	@media screen and (min-width: 1200px) {
		max-width: 100vw;
		overflow-x: hidden;
	}
}
</style>
