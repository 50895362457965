<template>
	<div class="nav-bar">
		<div class="operate" id="nav-bread">
			<!-- 汉堡按钮 -->
			<Hamburger />
			<!-- todo 面包屑 -->
			<BreadCrumb v-if="!route.meta.customBread" />
		</div>
		<div class="user-info" v-permission:zzd v-permission:jyd>
			<img class="avatar" src="./img/avatar.png" alt="" />
			<span class="name">{{ userInfo.name }}老师</span>
			<div class="icon-container">
				<div class="change-teacher" v-if="userInfo.__isOperator" @click="openSelectDialog">
					<img src="./img/change-teacher.png" />
				</div>
				<img class="icon" src="./img/logout.png" alt="" @click="loginOut" />
			</div>
		</div>
		<SelectTeacherDialog ref="selectTeacherRef" @on-selected="handleSelect" />
	</div>
</template>
<script setup lang="ts">
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { VuexStateKey } from '@/store/vuexEnum'
import { loginOut, setUserInfo } from '@/utils/utils'
import { checkTeacherPermission } from '@/utils/route'
import type { UserInfo } from '@/vo/UserInfo'
import { getUserInfo } from '@/utils/special/getUserInfo'
import Hamburger from '../hamburger/Hamburger.vue'
import BreadCrumb from '../breadCrumb/BreadCrumb.vue'
import { useRoute } from 'vue-router'
import { SchoolTeacherItem, apiChangeTeacherAccount } from '@/api/login'
import SelectTeacherDialog from '@/components/selectTeacher/SelectTeacherDialog.vue'

const selectTeacherRef = $ref<InstanceType<typeof SelectTeacherDialog>>()

const store = useStore()
const userInfo: UserInfo = $computed(() => store.state[VuexStateKey.userInfo])
const route = useRoute()

function openSelectDialog() {
	selectTeacherRef?.handleDialogVisible(true)
}
function handleSelect(teacherItem: SchoolTeacherItem) {
	if (getUserInfo()?.teacherId === teacherItem.teacherId) {
		// 已经选了，不需要再切换
		selectTeacherRef?.handleDialogVisible(false)
		return
	}
	apiChangeTeacherAccount(teacherItem.teacherId).then(async res => {
		selectTeacherRef?.handleDialogVisible(false)
		res.__isOperator = true
		setUserInfo({ ...res, accessToken: getUserInfo()?.accessToken || '' })
		await store.dispatch('setTeacherPermission')
		// 切换到当前老师所在的权限页面
		checkTeacherPermission(undefined, true)
	})
}
</script>

<style scoped lang="scss">
.nav-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;

	.operate {
		display: flex;
		align-items: center;
	}

	.user-info {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-left: auto;

		.avatar {
			width: 24px;
			height: 24px;
		}

		.icon-container {
			display: flex;
			height: 60px;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;

			.icon {
				margin-left: 12px;
			}
		}

		.change-teacher {
			img {
				width: 18px;
				height: 18px;
				margin-right: 8px;
			}
		}

		.name {
			font-size: 13px;
			font-family: $theme-text-family-1;
			font-weight: 400;
			color: $base-color-gray;
		}

		.icon {
			position: relative;
			top: 1px;
			width: 20px;
			height: 20px;
		}
	}
}
</style>
