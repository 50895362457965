<template>
	<div class="operator-select-dialog">
		<el-dialog
			v-model="visible"
			width="570px"
			top="86px"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			custom-class="g-dialog"
			title="请选择要切换的老师"
		>
			<el-select
				v-model="selectedSchoolId"
				style="width: 100%"
				placeholder="选择负责的学校"
				popper-class="ke-select-large"
				class="ke-select-large"
				@change="handleChangeSchool"
			>
				<el-option v-for="item in schoolList" :key="item.id" :value="item.id" :label="item.schoolName" />
			</el-select>
			<div class="teacher-list">
				<el-scrollbar max-height="334px">
					<el-table ref="tabelRef" :data="teacherList" v-loading="queryLoading" :cell-style="{ height: '52px' }" :highlight-current-row="true">
						<el-table-column label="" width="73px">
							<template #default="{ row }">
								<div class="radio" @click="handleSelect(row)">
									<img class="select-radio" v-if="row.__selected" src="./img/select-radio_selected.png" />
									<img class="select-radio" v-else src="./img/select-radio_unselect.png" />
								</div>
							</template>
						</el-table-column>
						<el-table-column label="姓名" prop="name" width="175px">
							<template #default="{ row }">
								<span style="color: #212440">{{ row.name }}</span>
							</template>
						</el-table-column>
						<el-table-column label="账号" prop="phone"></el-table-column>
					</el-table>
				</el-scrollbar>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" :disabled="!selectedTeachers.length" @click="onSelected">确定</el-button>
					<el-button @click="handleCancel">取消</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script setup lang="ts">
import { defineExpose, defineEmits } from 'vue'
import { ElTable } from 'element-plus'
import { getUserInfo } from '@/utils/special/getUserInfo'
import { OperatorSchoolItem, apiQueryStaffSchools, SchoolTeacherItem, apiQuerySchoolTeacherList } from '@/api/login'

const emits = defineEmits<{
	(e: 'on-selected', value: SchoolTeacherItem): void
}>()
let visible = $ref(false)
const tabelRef = $ref<InstanceType<typeof ElTable>>()
let selectedSchoolId = $ref<number | undefined>(undefined)
let schoolList = $ref<OperatorSchoolItem[]>([])
let teacherList = $ref<SchoolTeacherItem[]>([])
let queryLoading = $ref(false)

const selectedTeachers = $computed(() => teacherList.filter(item => item.__selected))
async function getSchoolList() {
	schoolList = await apiQueryStaffSchools()
	if (!selectedSchoolId) {
		// 优先选择当前老师所在的学校
		selectedSchoolId = getUserInfo()?.schoolId || undefined
		handleChangeSchool(selectedSchoolId)
	}
}
async function handleChangeSchool(id?: number) {
	if (id) {
		queryLoading = true
		teacherList = await apiQuerySchoolTeacherList(id)
		const findSelect = teacherList.find(item => item.teacherId === getUserInfo()?.teacherId)
		if (findSelect) {
			findSelect.__selected = true
			tabelRef?.setCurrentRow(findSelect)
		}
		queryLoading = false
	}
}
function handleSelect(item: SchoolTeacherItem) {
	teacherList.forEach(item => (item.__selected = false))
	item.__selected = true
	tabelRef?.setCurrentRow(item)
}
function handleDialogVisible(isVisible: boolean) {
	visible = isVisible
	if (visible) {
		getSchoolList()
	}
}

function handleCancel() {
	visible = false
}
function onSelected() {
	emits('on-selected', selectedTeachers[0])
}

defineExpose({
	handleDialogVisible,
})
</script>

<style scoped lang="scss">
.operator-select-dialog {
	:deep {
		.el-input {
			.el-input__wrapper {
				padding-left: 20px;
				.el-input__inner {
					height: 34px;
					&::-webkit-input-placeholder,
					&::placeholder {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #71717c;
					}
					&::-moz-placeholder {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #71717c;
					}
				}
			}
		}
		.el-table {
			thead th.is-leaf.el-table__cell {
				font-family: PingFangSC-Medium, PingFang SC !important;
				font-weight: 500 !important;
				color: #1a1a1a !important;
			}
			.el-table__row {
				height: 52px !important;
			}
		}
		.el-dialog {
			.el-dialog__footer {
				padding-top: 12px;
				padding-bottom: 12px;
			}
		}
	}
	.teacher-list {
		margin-top: 20px;
		.radio {
			padding-left: 20px;
			cursor: pointer;
			.select-radio {
				width: 14px;
				height: 14px;
			}
		}
	}
	.dialog-footer {
		.el-button {
			width: 48px;
			&:not(.el-button--primary) {
				border: 1px solid #71717c;
			}
		}
	}
}
</style>
