import { LocalStorageKey } from '@/config/enum'

export class VuexStateKey {
	static G = 'G'
	static userInfo = LocalStorageKey.USER_INFO
	static THIRD_USER_INFO = 'THIRD_USER_INFO'
	static ENUM_SUBJECT = 'ENUM_SUBJECT'
	static ENUM_SUBJECT_GROUP = 'ENUM_SUBJECT_GROUP'
	static ENUM_SUBJECT_INDEX_TYPE = 'ENUM_SUBJECT_INDEX_TYPE'
	static workName = 'workName'
	static TEACHER_PERMISSION = 'TEACHER_PERMISSION'
	static HAMBURGER_OPENED = 'HAMBURGER_OPENED'
	/** 扩展的面包屑 */
	static EXTEND_BREAD = 'EXTEND_BREAD'
	/** 是否展示footer */
	static FOOTER_SHOW = 'FOOTER_SHOW'
	static QUESTION_LIB_ENUM = 'QUESTOIN_LIB_ENUM'
	/** 是否展示关注公众号提示弹窗 */
	static SUBSCRIBE_SHOW = 'SUBSCRIBE_SHOW'
	static REVIEW_STATUS_DESC = 'REVIEW_STATUS_DESC'
	static REVIEW_TYPE = 'REVIEW_TYPE'
	static REVIEW_VALIDITY = 'REVIEW_VALIDITY'
}
export class VuexMutationKey {
	static updateUserInfo = 'updateUserInfo'
	static updateThirdUserInfo = 'updateThirdUserInfo'
	static setEnumSubject = 'setEnumSubject'
	static setWorkName = 'setWorkName'
	static setTeacherPermission = 'setTeacherPermission'
	static toggleHamburger = 'toggleHamburger'
	static setExtendBread = 'setExtendBread'
	static changeFooterShow = 'changeFooterShow'
	static setQuestionLibEnum = 'setQuestionLibEnum'
	static changeSubscribeShow = 'changeSubscribeShow'
}

export class VuexActionKey {
	static setEnumSubjectAsync = 'setEnumSubjectAsync'
	static setTeacherPermission = 'setTeacherPermission'
	static setQuestionLibEnum = 'setQuestionLibEnum'
}
