import { uniq } from 'lodash'
import { ref } from 'vue'
export const keepAliveArr = ref<string[]>([])

export function addKeepAliveRoute(routeName: string) {
	let list = keepAliveArr.value
	list.push(routeName)
	keepAliveArr.value = uniq(list)
}

export function removeKeepAliveRoute(routeName: string) {
	keepAliveArr.value = keepAliveArr.value.filter(i => i !== routeName)
}
