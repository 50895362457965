<template>
	<div class="layout">
		<el-container class="layout-container">
			<!-- 侧边栏 -->
			<el-aside :width="isClose ? '60px' : '180px'">
				<AsideMenu />
			</el-aside>
			<el-container class="right-container">
				<el-header height="60px">
					<NavBar />
				</el-header>
				<el-main>
					<router-view v-slot="{ Component, route }">
						<!-- <transition name="fade" mode="in-out"> -->
						<keep-alive :include="keepAliveArr">
							<component :is="Component" :key="route.name" />
						</keep-alive>
						<!-- :key="route.name"  -->
						<!-- </transition> -->
					</router-view>
				</el-main>
				<el-footer v-if="isShowFooter">
					<div id="page-footer"></div>
				</el-footer>
			</el-container>
		</el-container>
		<img class="back-top" src="./img/scroll-top_icon.png" v-if="showToTop" @click="backToTop" />
	</div>
</template>
<script lang="ts">
export default {
	name: 'Layout',
}
</script>
<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import AsideMenu from '@/components/asideMenu/index.vue'
import NavBar from '@/components/navBar/NavBar.vue'
import { VuexStateKey } from '@/store/vuexEnum'
import { getFooterShow } from '@/utils/layout'
import { keepAliveArr } from '@/utils/keepAlive'

const store = useStore()
const isClose = $computed(() => !store.state[VuexStateKey.HAMBURGER_OPENED])
const isShowFooter = $computed(() => getFooterShow())

let showToTop = $ref(false)
let scrollTopDistance = $ref(0)
function backToTop() {
	const timer = setInterval(() => {
		const speed = Math.floor(-scrollTopDistance / 5)
		const ele = document.querySelector('.el-main')
		if (ele) {
			ele.scrollTop = scrollTopDistance + speed
			if (scrollTopDistance <= 0) {
				clearInterval(timer)
			}
		}
	}, 10)
}

function scrollTop() {
	scrollTopDistance = document.querySelector('.el-main')?.scrollTop || 0
	if (scrollTopDistance >= window.innerHeight) {
		showToTop = true
	} else {
		showToTop = false
	}
}
onMounted(() => {
	window.addEventListener('scroll', scrollTop, true)
})
onUnmounted(() => {
	window.removeEventListener('scroll', scrollTop, true)
})
</script>

<style scoped lang="scss">
:deep .el-aside {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.1) !important;
	height: 100vh !important;
	scrollbar-width: none; /* firefox */
	-ms-overflow-style: none; /* IE 10+ */

	transition: width 0.3s ease;
	z-index: 2100;

	&::-webkit-scrollbar {
		display: none;
	}
}
.layout-container {
	max-height: 100vh;
	overflow: hidden;
}
.el-header {
	position: relative;
	box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.1) !important;
}
.right-container {
	position: relative;
}
// main
.el-main {
	padding: 20px;
	background: #fafbfe;
}
.el-footer {
	height: 48px;
	background: #ffffff;
	box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.1) !important;
}
.back-top {
	position: fixed;
	width: 36px;
	height: 36px;
	bottom: 60px;
	right: 4px;
	cursor: pointer;
}
</style>
