import { LocalStorageKey, SessionStorageKey } from '@/config/enum'
import { UserInfo } from '@/vo/UserInfo'
import { jydNeedLogin, zzdNeedLogin } from '../route'

export const getUserInfo = function (): UserInfo | undefined {
	let userInfo: UserInfo | undefined
	try {
		const search = location.search
		const thirdLogin = zzdNeedLogin(search) || jydNeedLogin(search)
		const thirdInfoString = sessionStorage.getItem(SessionStorageKey.THIRD_USER_INFO) || ''
		if (thirdLogin || thirdInfoString) {
			userInfo = getThirdUserInfo()
		} else {
			userInfo = JSON.parse(localStorage.getItem(LocalStorageKey.USER_INFO) || '')
		}
	} catch (error) {
		userInfo = undefined
	}
	return userInfo
}

export const getThirdUserInfo = function (): UserInfo | undefined {
	let userInfo: UserInfo | undefined
	try {
		const search = location.search
		const thirdLogin = zzdNeedLogin(search) || jydNeedLogin(search)
		const thirdInfoString = sessionStorage.getItem(SessionStorageKey.THIRD_USER_INFO) || ''
		if (thirdLogin) {
			// 只能判断第一次/这里是有前提用户数据不混合（满足需求）
			if (thirdInfoString) userInfo = JSON.parse(thirdInfoString)
		} else if (thirdInfoString) {
			userInfo = JSON.parse(thirdInfoString)
		}
	} catch (error) {
		userInfo = undefined
	}
	return userInfo
}

