export class UrlQueryKey { }

export class LocalStorageKey {
	static USER_INFO = 'userInfo'
	static WORK_NAME = 'workName'
	static PRINT_PARAM = 'printParam'
	static UPLOAD_TASK_TYPE = 'uploadTaskType'

	/********** 作业批改相关 **********/
	static HOMEWORK_REVIEW = 'homeworkReview'
	static HOMEWORK_REVIEW_STUDENT = 'homeworkReviewStudent'

	static HOMEWORK_ANSWER_INFO = 'homeworkAnswerInfo'
	static HOMEWORK_STUDENT_REVIEW_INFO = 'homeworkStudentReviewInfo'
	static HOMEWORK_QUESTION_REVIEW_INFO = 'homeworkQuestionReviewInfo'

	static HOMEWORK_DETAIL_DATA_TYPE = 'homeworkDetailDataType'
	/********** 作业批改相关 **********/

	/** 题库相关 start */
	static QUESTION_LIB_QUESY = 'questionLibQuery'
	/** 题库相关 end */
}

export class TimeKey {
	static SMS = 60
}
export class SessionStorageKey {
	static USER_INFO = LocalStorageKey.USER_INFO
	static THIRD_USER_INFO = 'THIRD_USER_INFO'
	static LOGIN_ERR_MSG = 'LOGIN_ERR_MSG'
	static LOGIN_APP_ID = 'LOGIN_APP_ID'
}

export class EventKey { }

export class ErrorServerKey { }

export class APP_ID {
	static ZZD = 'open315665169580033'
	static JYD = 'open307508276363265'
}
