const G = {
	isMock: false,
	ENV: import.meta.env.MODE,
	platform: import.meta.env.VITE_PLATFORM,
	defaultTitle: ' ',
	isKeepAlive: false,
	projectId: 100001,
	cli_type: '1',
}

export default G

export const DOMAIN_MAP = {
	user: import.meta.env.VITE_USER_URL,
	1: import.meta.env.VITE_BASE_URL,
	2: import.meta.env.VITE_BASE_URL_V2,
	node: import.meta.env.VITE_NODE_URL,
	log: import.meta.env.VITE_LOG_URL,
	ass: import.meta.env.VITE_ASS_URL,
	review: import.meta.env.VITE_REVIEW_URL,
	question: import.meta.env.VITE_QUESTION_URL,
	question2: import.meta.env.VITE_QUESTION_URL2,
}

export const APP_CHANNEL = {
	zzd: 'zzd',		// 浙政钉
	jyd: 'jyd'		// 教育钉
}

export const THIRD_APP_MAP = {
	open315665169580033: APP_CHANNEL.zzd,
	open307508276363265: APP_CHANNEL.jyd
}
