<template>
	<div class="aside-menu">
		<el-menu
			:default-active="activePath"
			:collapse="isCollapse"
			background-color="#fff"
			text-color="#71717C"
			active-text-color="#0089FF"
			router
			:default-openeds="defaultOpens"
		>
			<template v-for="route in menuRoutes" :key="route.name">
				<MenuItem :route="route" :activeRoute="activeRoute" v-if="route.children && route.children.find(item => item.meta?.menuShow)" />
			</template>
		</el-menu>
	</div>
</template>
<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router'
import { filterRoutes } from '@/utils/route'
import { useStore } from 'vuex'
import { VuexStateKey } from '@/store/vuexEnum'
import MenuItem from './MenuItem.vue'
const store = useStore()
const isCollapse = $computed(() => !store.state[VuexStateKey.HAMBURGER_OPENED])
const router = useRouter()
const menuRoutes = filterRoutes(router.getRoutes())
const route = useRoute()
const activeRoute = $computed(() => {
	return route.matched[0]
})
const activePath = $computed(() => route.matched[0].children.find(item => item.name === route.meta?.parentName || item.name === route.name)?.path || '')
const defaultOpens = $computed(() => menuRoutes.map(item => item.path))
</script>
<style scoped lang="scss">
.aside-menu {
	.el-menu {
		border: none;
		height: 100%;
		width: 100% !important;
	}
}
</style>
