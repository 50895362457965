import type { App, Directive, DirectiveBinding } from 'vue'
import { UserInfo } from '@/vo/UserInfo'
import { getUserInfo } from '@/utils/special/getUserInfo'
import { APP_ID } from '@/config/enum'

const hasPermission = (value: string): boolean => {
    let userinfo: UserInfo | undefined = getUserInfo()
    if (!userinfo) {
        throw new Error('permission.hasNoPermission')
    }
    if (userinfo.appId && value && APP_ID[value.toUpperCase() as keyof APP_ID] === userinfo.appId) {
        return false
    }
    return true
}
function hasPermi(el: HTMLElement, binding: DirectiveBinding) {
    const { arg } = binding
    const flag = hasPermission(arg || '')
    if (!flag) {
        el.remove();
    }
}
const mounted = (el: HTMLElement, binding: DirectiveBinding<any>) => {
    hasPermi(el, binding)
}

const permiDirective: Directive = {
    mounted
}

export const setupPermissionDirective = (app: App<Element>) => {
    app.directive('permission', permiDirective)
}

export default permiDirective