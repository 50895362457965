import { getUserInfo } from '@/utils/special/getUserInfo'
import { NpsEnv } from '@/extend'
export function npsMeter(key?: string) {
	if (!key) return
	let userInfo = getUserInfo()
	new npsmeter({
		key,
		user_id: userInfo?.userId || '',
		user_name: userInfo?.name || '',
	})
	npsmeter.open(key)
}

export function getNpsMeterId(config?: NpsEnv) {
	return config?.[import.meta.env.MODE as keyof NpsEnv]
}
