<template>
	<el-dialog
		v-model="visible"
		width="450px"
		top="30vh"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		custom-class="g-dialog-v3"
		:title="title"
	>
		<div class="content">
			<div class="img-con">
				<img src="../img/qrCode-big.png" alt="" />
			</div>

			<el-button type="primary" class="btn" @click="handleClose">关闭</el-button>
		</div>
	</el-dialog>
</template>
<script setup lang="ts">
import { useStore } from 'vuex'
import { VuexMutationKey } from '@/store/vuexEnum'
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
	showDialog: {
		type: Boolean,
		required: true,
	},
	title: {
		type: String,
		required: true,
	},
})

const store = useStore()
const emits = defineEmits(['close-dialog'])
const visible = $computed(() => props.showDialog)

function handleClose() {
	emits('close-dialog')
	store.commit(VuexMutationKey.changeSubscribeShow, 1)
}
</script>

<style scoped lang="scss">
.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.img-con {
		width: 120px;
		height: 120px;
		border: 1px solid #c7ccd0;
		border-radius: 4px;
		overflow: hidden;
		padding: 3px;
		box-sizing: border-box;
	}
	img {
		width: 100%;
		height: 100%;
	}
	.btn {
		width: 48px;
		font-size: 12px;
		margin-top: 24px;
	}
}
</style>
