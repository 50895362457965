import { RouteRecordRaw, LocationQuery, RouteLocationNormalized } from 'vue-router'
import store, { TeacherPermissionInfo } from '@/store'
import { VuexStateKey, VuexMutationKey } from '@/store/vuexEnum'
import { getThirdUserInfo, getUserInfo } from '@/utils/special/getUserInfo'
import { ElMessage } from 'element-plus'
import { routes, router } from '@/router'
import { flatten } from 'lodash'
import { clearThirdUserInfo, loginOut } from './common/user'
import { APP_ID, SessionStorageKey } from '@/config/enum'
/**
 * 由于router.getRoutes获取到的路由信息包含每个路由信息以及含有children信息的整体路由信息
 * 所以要获取出route下的所有children，方便将脱离层级的路由剔除
 * @param routes 通过router.getRoutes获取到的路由信息
 */
const getChildrenRoutes = (routes: RouteRecordRaw[]) => {
	const childrenRoutes: RouteRecordRaw[] = []
	const teacherPermission = store?.state[VuexStateKey.TEACHER_PERMISSION] as unknown as TeacherPermissionInfo[]
	const openTypeList = teacherPermission.filter(item => item.permission).map(item => item.openServiceType)
	routes.forEach(route => {
		if (route.children && route.children.length > 0) {
			childrenRoutes.push(...route.children)
		}
	})
	childrenRoutes.forEach(item => {
		if (item.meta?.menuShow && openTypeList.indexOf(item.meta.openServiceType || -1) < 0) {
			item.meta.menuShow = false
		}
	})
	return childrenRoutes
}

/**
 * 通过得到的childrenRoutes，将脱离层级的route过滤出去，只保留有层级的路由信息
 * 并且获取到所有含有子路由信息的路由
 * @param routes
 */
export const filterRoutes = (routes: RouteRecordRaw[]) => {
	const childrenRoutes = getChildrenRoutes(routes)
	const unRemovedRoutes = routes.filter(route => {
		return (
			route.children?.length &&
			route.children.length > 0 &&
			!childrenRoutes.find(childrenRoute => {
				return childrenRoute.path === route.path
			})
		)
	})
	// 过滤掉不需要展示的子路由信息
	unRemovedRoutes.forEach(route => {
		route.children = route.children!.filter(route => route.meta?.menuShow)
	})
	return unRemovedRoutes
}

export function checkTeacherPermission(subscribeType?: 1 | 2 | 3, changeTeacher?: boolean) {
	const teacherPermission = store?.state[VuexStateKey.TEACHER_PERMISSION] as unknown as TeacherPermissionInfo[]
	const permissionList = teacherPermission.filter(item => item.permission)
	if (!permissionList.length) {
		ElMessage.info('未开通权限，请联系管理员!')
	} else {
		const routeChildrens = flatten(routes.map(route => route.children))
		// 找到配置的第一个拥有权限的路由，进行跳转
		const findedRoute = routeChildrens.find(item => item?.meta?.menuShow && item.meta.openServiceType === permissionList[0].openServiceType)
		if (findedRoute) {
			if (changeTeacher) {
				return router
					.replace({
						name: findedRoute.name,
					})
					.then(() => {
						location.reload()
						ElMessage({
							message: `已成功切换到${getUserInfo()?.name}老师`,
							duration: 5000,
							type: 'success',
						})
					})
			} else {
				router
					.push({
						name: findedRoute.name,
					})
					.then(() => {
						if (subscribeType) {
							store.commit(VuexMutationKey.changeSubscribeShow, subscribeType)
						}
					})
			}
		} else {
			ElMessage.info('未开通权限，请联系管理员!')
		}
	}
}

// 解析query参数
export function parseURLParams(url: string) {
	const queryParams: any = {}
	// 正则匹配规则
	const reg = /([^?=&]+)=([^?=&]+)/g
	// 利用replace替换
	url.replace(reg, function (...rest): any {
		// 用伪数组进行键值对拼接
		queryParams[rest[1]] = rest[2]
	})
	// 返回参数键值对
	return queryParams
}

export type ThirdReult = {
	third: boolean
	zzd: boolean
	jyd: boolean
}

// 判断是否来自三方应用：浙政钉
export function checkThirdApp(to: RouteLocationNormalized, from: RouteLocationNormalized): ThirdReult {
	const toQ = to.query
	const thirdResult = { third: false, zzd: false, jyd: false }
	if (isZZDApp(toQ)) {
		thirdResult.third = true
		thirdResult.zzd = true
	} else if (isJYDApp(toQ)) {
		thirdResult.third = true
		thirdResult.jyd = true
	}
	return thirdResult
}

// 浙政钉
export function isZZDApp(query: any): boolean {
	if (zzdNeedLogin(query)) {
		clearThirdUserInfo()
		return true
	} else if (query.appId && query.appId !== APP_ID.ZZD) {
		return false
	} else {
		const thirdUserInfo = getThirdUserInfo()
		return thirdUserInfo && thirdUserInfo.appId === APP_ID.ZZD || false
	}
}

// 浙政钉:只要包含这几个参数重新登陆
export function zzdNeedLogin(query: any) {
	if (query && typeof query === 'string') {
		query = parseURLParams(query)
	}
	if (query && query.appId && query.teacherId && query.sign && query.nonce) {
		return true
	} else {
		return false
	}
}

// 教育钉
export function isJYDApp(query: any): boolean {
	if (jydNeedLogin(query)) {
		clearThirdUserInfo()
		return true
	} else {
		// 这里不做appChannel判断
		const thirdUserInfo = getThirdUserInfo()
		return !!thirdUserInfo
	}
}


// 教育钉:只要包含这几个参数重新登陆
export function jydNeedLogin(query: any) {
	if (query && typeof query === 'string') {
		query = parseURLParams(query)
	}
	if (query && query.appId && query.sign && query.nonce) {
		return true
	} else {
		return false
	}
}
