<template>
	<div class="app-bread">
		<el-breadcrumb separator="/">
			<template v-for="bread in matches" :key="bread.name">
				<el-breadcrumb-item class="link" :class="{ current: bread.path === currentPath }" :to="{ path: bread.path, query: bread.query }">{{
					bread.meta?.title
				}}</el-breadcrumb-item>
			</template>
			<el-breadcrumb-item v-if="extendBreadName" class="link">
				<div class="max-w-[50vw] oneline cursor-pointer" @click="goPre">{{ extendBreadName }}</div>
			</el-breadcrumb-item>
		</el-breadcrumb>
	</div>
</template>
<script setup lang="ts">
import { watch, defineProps } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getExtendBread } from '@/utils/extendBread'
const route = useRoute()
const router = useRouter()
let matches: any[] = $ref([])
let currentPath = $ref('')
const extendBreadName = $computed(() => getExtendBread())
const props = defineProps<{
	breadData?: any[]
}>()

function goPre() {
	router.go(-1)
}
watch(
	() => [route, props.breadData],
	([currentRoute, breadData]) => {
		if (breadData && breadData?.length) {
			currentPath = breadData[breadData.length - 1].path
			matches = breadData
		} else {
			currentPath = currentRoute.path
			matches = []
			// 将顶层路由放进去
			if (currentRoute.matched[0]) matches.push(currentRoute.matched[0])
			// 寻找该页面的父级路由
			const parentRoute = currentRoute.matched[0].children.filter(item => item?.name === currentRoute.meta?.parentName || item?.name === currentRoute?.name)[0]
			if (parentRoute) matches.push(parentRoute)
		}
	},
	{
		immediate: true,
		deep: true,
	}
)
</script>

<style scoped lang="scss">
.app-bread {
	margin-left: 20px;
	:deep .el-breadcrumb {
		font-size: 15px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: $theme-text-color;
		.el-breadcrumb__inner {
			font-size: 15px;
			color: $theme-text-color;
			&:hover {
				color: $theme-text-color;
			}
		}
		.el-breadcrumb__separator {
			color: $theme-text-color;
		}
		.el-breadcrumb__item {
			&.link:not(.current) {
				&:hover {
					.el-breadcrumb__inner {
						color: $base-color-blue;
						cursor: pointer !important;
					}
				}
			}
		}
	}
}
</style>
